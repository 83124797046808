import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bearded dragons are docile, low-maintenance pets that enjoy hanging out in their terrariums. You can build the right habitat for them in the form of a terrarium. Your bearded dragon's terrarium provides a sense of comfort, security, and well-being. This article will discuss the supplies you will need to build a terrarium for your bearded dragon and provide instructions on “how to build a terrarium for a bearded dragon”. If you follow these steps closely, you'll successfully build a healthy and safe environment for your pet.`}</p>
    <h2>{`Step 1: Gather the Supplies`}</h2>
    <p>{`You'll need`}</p>
    <ul>
      <li parentName="ul">{`A glass enclosure`}
        <ul parentName="li">
          <li parentName="ul">{`You can buy a prebuilt enclosure or buy glass and aquarium silicone for sticking the glass together yourself.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Sand`}</li>
      <li parentName="ul">{`UVB Light`}</li>
      <li parentName="ul">{`Heating element`}</li>
      <li parentName="ul">{`A basking log or rock (something for the bearded dragon to "sunbathe" on)`}</li>
      <li parentName="ul">{`shaddlow dishes for food and water`}</li>
      <li parentName="ul">{`live insects (you'll probably want tweezers, too!)`}</li>
    </ul>
    <h2>{`Step 2: Choose a Location`}</h2>
    <p>{`First, decide where you'll be placing your bearded dragon's new terrarium. The spot should be out of direct sunlight (terrariums can get quite hot when placed in the sun) and away from anything that could disrupt the animal's rest. Once you've selected a spot, you can begin prepping the tank for its new tenant.`}</p>
    <h2>{`Step 3: Assemble the Terrarium`}</h2>
    <p>{`When building a terrarium for bearded dragons, there are some primary attributes to keep in mind. The terrarium size depends on the size of the bearded dragon. A good rule of thumb is that the terrarium should be at least twice the length of the bearded dragon in length, width, and height. Bearded dragons can live for ten years, so building an enclosure is a good idea.`}</p>
    <p>{`A 20-gallon tank is suitable for a bearded baby dragon, and an adult will be happiest in a 75 to 125-gallon tank. The terrarium should be about 48 inches long, 24 inches wide, 24 inches high, and made up of glass. The bearded dragon needs enough space to move around freely within its terrarium. It allows them to exercise and stay active.`}</p>
    <p>{`One of the trickier parts of owning a bearded dragon is managing tanks. If you buy or adopt a baby bearded dragon, you won't want to place him in an adult sized tank. Due to this, a lot of bearded dragon owners have two tanks -- a 40 gallon tank used for juvenile dragons, and a 75 or 125 gallon tank used for adults. They'll start their baby in the 40 gallon tank and once they reach adult-hood, move them to their 75 or 125 gallon tank.`}</p>
    <p>{`If buying multiple tanks sounds difficult, you can always purchase or make an insert to divide a 75 or 120 gallon tank into a smaller tank, too! Note: Bearded dragons grow fast. A 20 gallon tank will not lost you nearly as long as you may think. Save yourself a trip to the store and skip the 20 gallon or buy it and a larger tank at the same time.`}</p>
    <h2>{`Step 4: Add substrate and decorations`}</h2>
    <p>{`Start by lining the base of the aquarium with a substrate such as sand or mulch. Ensure that the substrate is in-depth for your lizard to bury himself up to the dragon's chin if he wants to (about 4 inches deep). It is what your bearded dragon will walk on, so make sure it's smooth, free of rocks and hard objects, and free from any contaminants.`}</p>
    <p>{`You can use live plants, but they must be non-toxic and carefully tended to avoid mold growth or other dangers. Alternately, you can use artificial plants if they're not made with toxic materials like PVC or lead paint. Bearded dragons love to roam and explore, so make sure plenty of room is provided for climbing.`}</p>
    <p>{`You'll also want to find a home for your basking spot mentioned earlier. Remember, this is a log or a rock. Your bearded dragon may enjoy the heat, but its important to make sure their basking spot is not too hot.`}</p>
    <p>{`You'll also want to buy or build a hiding spot. You can purchase a shelter for bearded dragons at your local pet store or you can make your own. This can be as simple as some cardboard with a hole cut into it. These are very important elements of any terrarium because they give your bearded dragon a sense of security while also providing shelter from predators.`}</p>
    <p>{`Set up the water and food dish - it is important that your Bearded Dragon has access to clean water and food.`}</p>
    <p>{`Finally, add a water bowl large enough for your lizard to soak in and a hiding place somewhere in the enclosure.`}</p>
    <h2>{`Step 6: Top off the terrarium`}</h2>
    <p>{`You'll also need to place a top on the terrarium. Generally wire meshes are not suggested for bearded dragons as they can be dangerous for them, but if you can ensure that your dragon can not reach the wire mesh, it makes a suitable topper for the terrarium. Alternatively, you can use a fiberglass screen. Placing some holes in the screen should provide suitable ventilation.`}</p>
    <h1>{`Step 7: Monitor Thermal Gradient`}</h1>
    <p>{`These lizards love warmth—their native habitat is very hot and dry—so you'll need to provide plenty of heat for your pet. A thermal gradient is a temperature gradient across the enclosure, with hotter temperatures on one side of the tank (the basking area) and cooler temperatures on the other end. The bearded dragon should be able to freely move between the two, depending on whether it wants to warm up or cool down.`}</p>
    <ul>
      <li parentName="ul">{`The basking area needs to be at least 90ᵒF (32.2ᵒC), but ideally around 95ᵒF (35ᵒC). It can be achieved using a basking lamp placed directly above the hot spot within the enclosure.`}</li>
      <li parentName="ul">{`The cool side needs to be around 80ᵒF (26.7ᵒC).`}</li>
      <li parentName="ul">{`During the night, ambient temperatures should drop to slightly below 75ᵒF (24ᵒC).`}</li>
      <li parentName="ul">{`Install the Under Tank Heater and Thermostat to monitor the temperature`}</li>
    </ul>
    <h2>{`Step 8: Light Monitoring`}</h2>
    <p>{`Bearded dragons are diurnal animals, meaning they are active and sleep at night during the day. For this reason, you need to provide daytime lighting for them and nighttime lighting for their nocturnal activities. A full spectrum bulb usually provides lighting. It allows your bearded dragon to mimic natural sunlight patterns and absorb UVB rays for vitamin D3 production. Instead, you should attach an overhead UVB bulb covering about half his enclosure.`}</p>
    <h2>{`Step 9: Monitoring the Humidity`}</h2>
    <p>{`Monitoring humidity is another important aspect of bearded dragon terrariums. The humidity in the terrarium should be between 50% and 70%. A digital hygrometer—a device that measures humidity—is a great way to keep track of the humidity in your terrarium. If you don't have one, you can stick your finger into the substrate and assess whether it feels moist or dry. Mistake the substrate lightly with your spray bottle if the substrate is moist. If the substrate is dry, mist it until it feels slightly damp.`}</p>
    <h2>{`Enjoy life with your bearded dragon`}</h2>
    <p>{`Bearded dragons make interesting pets, requiring a specific environment to thrive. A terrarium is a great way to provide this environment, and it can be easily built at home. Check the terrarium regularly to make sure heat and humidity levels are correct. If you notice any changes, you will need to fix the issue immediately.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      